<template>
  <div>
    <div class="row">
      <div class="col-12">
        <table>
          <thead>
            <tr>
              <td
                v-for="column in columns"
                :key="column.name"
                @click="column.sortable === true ? sortBy(column.name) : ''"
                :class="
                  sortKey === column.name
                    ? sortOrders[column.name] > 0
                      ? 'headerSortUp'
                      : 'headerSortDown'
                    : ''
                "
                :style="'width:' + column.width + ';' + 'cursor:pointer;'"
              >
                {{ column.label }}
              </td>
              <td><i class="icon icon-cogs"></i></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in tblrows" :key="row.id">
              <td>{{ row.id }}</td>
              <td>{{ row.product }}</td>
              <td>€{{ row.gross }}</td>
              <td>€{{ row.nett }}</td>
              <td>{{ row.quantity }}</td>
              <td>{{ row.business }}</td>
              <td>{{ TimeParse(row.created_at) }}</td>
              <td>{{ row.status }}</td>
              <td>
                <div
                  class="btn btn-orange"
                  v-on:click="UpdateModal(row.id, row.status)"
                >
                  Update Status
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-6">
        Displaying {{ count }} of {{ total_filter }}. Total of {{ total }} rows.
      </div>
      <div class="col-6">
        <div class="btn-group float-right">
          <div class="btn btn-green" v-on:click="GetDataPrev">
            &larr; PREVIOUS
          </div>
          <div class="btn btn-green" v-on:click="GetDataNext">NEXT &rarr;</div>
        </div>
      </div>
    </div>
    <div id="savechanges-modal" class="modal">
      <div class="modal-content">
        <div class="row">
          <span class="close">&times;</span>
        </div>
        <div class="row">
          <div class="col-12">
            <p>Update the order status for this sale.</p>
            <br />
            <br />
            <label for="updateStatus">Status</label>
            <select
              name="updateStatus"
              id="updateStatus"
              class="input"
              v-model="updateStatus"
            >
              <option value="ORDER PENDING">ORDER PENDING</option>
              <option value="ORDER ACCEPTED">ORDER ACCEPTED</option>
              <option value="PREPARING ORDER">PREPARING ORDER</option>
              <option value="ORDER SHIPPED">ORDER SHIPPED</option>
            </select>
            <br />
            <br />
            <br />
            <button class="btn btn-green" v-on:click="SaveChanges">
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
export default {
  name: "Admin_ShopOrders_ListDatatable",
  setup() {
    const store = useStore();

    return { store };
  },
  data() {
    let sortOrders = {};
    let columns = [
      { width: "auto", label: "ID", name: "id", sortable: true },
      {
        width: "auto",
        label: "Product",
        name: "shop_products.title",
        sortable: true,
      },
      {
        width: "auto",
        label: "€ Gross",
        name: "gross",
        sortable: true,
      },
      {
        width: "auto",
        label: "€ Nett",
        name: "nett",
        sortable: true,
      },
      {
        width: "auto",
        label: "Quantity",
        name: "quantity",
        sortable: true,
      },
      {
        width: "auto",
        label: "Business",
        name: "business_id",
        sortable: true,
      },
      {
        width: "auto",
        label: "Date of Order",
        name: "created_at",
        sortable: true,
      },
      {
        width: "auto",
        label: "Status",
        name: "status",
        sortable: true,
      },
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      tblrows: [],
      count: 0,
      order: [0, "DESC"],
      search: "",
      offset: 0,
      total: 0,
      limit: 20,
      total_filter: 0,
      next: 0,
      prev: 0,
      columns: columns,
      sortKey: "id",
      sortOrders: sortOrders,
      updateId: null,
      updateStatus: null,
    };
  },
  methods: {
    GetData() {
      let body = {
        search: this.search,
        order: this.order,
        limit: this.limit,
        offset: this.offset,
      };
      this.store
        .dispatch("Admin/GetShopSalesDatatable", body)
        .then((result) => {
          this.total = result.total;
          this.total_filter = result.total_filter;
          this.tblrows = result.data;
          this.count = this.offset + this.tblrows.length;
          this.prev =
            this.offset - this.limit < 0 ? 0 : this.offset - this.limit;
          this.next =
            this.offset + this.tblrows.length > this.total_filter
              ? this.total_filter
              : this.offset + this.tblrows.length;
        })
        .catch((err) => {
          console.log(err);
          this.total = 0;
          this.total_filter = 0;
          this.tblrows = [];
          this.count = 0;
          this.prev = 0;
          this.next = 0;
        });
    },
    GetDataSearch() {
      this.tblrows = [];
      this.offset = 0;

      this.GetData();
    },
    GetDataNext() {
      if (this.next != this.total_filter) {
        console.log(this.next);
        this.offset = this.next;
        this.GetData();
      }
    },
    GetDataPrev() {
      if (this.offset !== 0) {
        this.offset = this.prev;
        this.GetData();
      }
    },
    getIndex(array, key, value) {
      return array.findIndex((i) => i[key] == value);
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      let name = this.getIndex(this.columns, "name", key);
      let dir = this.sortOrders[key] > 0 ? "ASC" : "DESC";
      this.order = [name, dir];

      this.GetData();
    },
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
    UpdateModal(id, status) {
      this.updateId = id;
      this.updateStatus = status;

      // Get the modal
      var modal = document.getElementById("savechanges-modal");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("close")[0];

      // When the user clicks on the button, open the modal
      modal.style.display = "block";

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
      };

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {
        if (event.target == modal) {
          modal.style.display = "none";
        }
      };
    },
    SaveChanges() {
      console.log(this.updateId, this.updateStatus);
      this.store
        .dispatch("Admin/UpdateShopSaleStatus", {
          id: this.updateId,
          status: this.updateStatus,
        })
        .then((result) => {
          console.log(result.messages.toString());
          this.GetData();
        })
        .catch((err) => {
          alert(err.messages.toString());
        });
    },
  },
  mounted() {
    this.GetData();
  },
};
</script>

<style>
.error {
  height: auto;
  width: 100%;
  background: #e34343;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #c52f2f;
  margin-bottom: 5px;
}

.success {
  height: auto;
  width: 100%;
  background: #04a394;
  padding: 10px;
  box-sizing: border-box;
  text-align: center;
  display: block;
  border-radius: 10px;
  font-size: 17px;
  color: #fff;
  border: 1px solid #069486;
  margin-bottom: 5px;
}

table .input {
  height: 30px;
  width: 100%;
  font-size: 17px;
  color: #4d4d4d !important;
  text-align: center;
  display: block;
  background: #fff !important;
  outline: 0;
  border: 1px solid #c8ced5;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 1px;
  box-sizing: border-box;
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 99999;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
  border-radius: 10px;
}

/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>
