<template>
  <div v-if="this.$route.name == 'Admin_Shop'">
    <Navigation
      v-bind:Breadcrumb="[
        { name: 'Home', route: 'Home' },
        { name: 'Staff', route: 'Admin' },
        { name: 'Shop', route: 'Admin_Shop' },
      ]"
    />
    <div class="placeholder">
      <div class="row">
        <Admin_ShopOrders_ListDatatable />
      </div>
    </div>
  </div>
  <div v-else>
    <router-view />
  </div>
</template>

<script>
import { useStore } from "vuex";
document.title = "MicronAgritech | Admin";

import Navigation from "../components/Navigation.vue";
import Admin_ShopOrders_ListDatatable from "@/components/Admin.ShopOrders.ListDatatable.vue";

export default {
  name: "Admin_Shop",
  setup() {
    const store = useStore();

    return { store };
  },
  props: {},
  components: {
    Navigation,
    Admin_ShopOrders_ListDatatable,
  },
  computed: {
    FullName() {
      return this.store.state["User"].info.name;
    },
    isStaff() {
      return this.store.state["User"].info.is_staff;
    },
    avatar() {
      return this.store.state["User"].info.avatar;
    },
  },
  methods: {
    TimeParse(time) {
      let date = new Date(time);
      let returntime = `${("0" + date.getDate()).slice(-2)}/${(
        "0" +
        (date.getMonth() + 1)
      ).slice(-2)}/${date.getFullYear()} ${("0" + date.getHours()).slice(
        -2
      )}:${("0" + date.getMinutes()).slice(-2)}`;

      return returntime;
    },
  },
  data() {
    return {
      productsList: [],
    };
  },
  created() {},
};
</script>

<style>
.infoBlock {
  font-weight: 800;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  padding: 10px;
  font-size: 35px;
}
</style>
